import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
} from "reactstrap"
import axios from "axios"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import { getPaymentRequestDetailPublic, setApplePayInitiatePayment } from "store/actions"
import QRCode from 'qrcode.react'

//TODO:   USING PROD "https://pal.developerqa.info/api/aps/applepay-test",
//TODO: USING "https://dana.payalllink.com/applepay/"


const ApplePayButton = props => {
  const {
    match: { params },
    onSetApplePayInitiatePayment,
    onGetPaymentRequestDetail,
    retrievedPaymentRequest,
    retrievedInitiatePaymentResponse,
  } = props


  const [paymentRequest, setPaymentRequest] = useState()
  const [paymentGateway, setPaymentGateway] = useState()
  const [apsApplePayEmbedded, setApsApplePayEmbedded] = useState()
  const [redirectLink, setRedirectLink] = useState(undefined)
  const [qrData, setQrData] = useState('')

  useEffect(() => {
    if (params && typeof params.id === "string") {
      onGetPaymentRequestDetail(params.id)
    }
  }, [params, onGetPaymentRequestDetail])

  useEffect(() => {
   if(retrievedInitiatePaymentResponse && retrievedInitiatePaymentResponse.url !== ""){
    setRedirectLink(retrievedInitiatePaymentResponse.url)
   }
  }, [retrievedInitiatePaymentResponse])

  useEffect(() => {
    if(redirectLink !== undefined){
      window.location.replace(redirectLink)
    }
   }, [redirectLink])

  useEffect(() => {
    if(retrievedPaymentRequest){
      setPaymentRequest(retrievedPaymentRequest.paymentRequestDetails)
      setPaymentGateway(retrievedPaymentRequest.pgDetails)
      
    }
  }, [retrievedPaymentRequest])

  useEffect(() => {
    if (paymentGateway) {
      paymentGateway.map(pg => {

        if (pg?.pgId === "PG00002") {
          var aps = paymentGateway?.filter(x => x.pgId == "PG00002")[0]
          setApsApplePayEmbedded(aps.data.applepay_embedded)
        }
      })
    }
  }, [paymentGateway])

  useEffect(() => {
    
  }, [paymentGateway])


  useEffect(() => {

    if(paymentRequest && apsApplePayEmbedded ){

     
      setQrData(apsApplePayEmbedded.applepayWebsite + paymentRequest.paymentRequestId)

      if(paymentRequest.generatedLinkStatus !== "Expired" && paymentRequest.paidDetails === null ){

    const applePayScript = document.createElement("script")
    applePayScript.src = "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js"
    applePayScript.async = true

    applePayScript.onload = function handleScriptLoaded() {
      const applePayButton = document.querySelector("apple-pay-button")

      applePayButton.addEventListener("click", async e => {
        e.preventDefault()
        onApplePayButtonClicked()
      });

      function onApplePayButtonClicked() {
        if (!ApplePaySession) {
          return;
        }

        const request = {
          countryCode: "QA",
          currencyCode: paymentRequest.amountDetails.currency,
          merchantCapabilities: ["supports3DS"],
          supportedNetworks: ["visa", "masterCard"],
          total: {
            label: "Dana Travel & Tourism Services ",
            type: "final",
            amount: paymentRequest.amountDetails.totalAmount,
          },
        };

       

        const session = new ApplePaySession(3, request);

        // Open the payment sheet
        session.onvalidatemerchant = event => {
          // Fetch the merchant session to validate the payment request
          fetch("https://pal.acoqa.com/api/aps/applepay/session", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(apsApplePayEmbedded.merchantId),
          })
            .then(res => res.json()) // Parse response as JSON.
            .then(merchantSession => {
            
              session.completeMerchantValidation(merchantSession)
            })
            .catch(err => {
              console.error("Error fetching merchant session", err)
            })
        };

        // Process token and respond to customer
        session.onpaymentauthorized = async event => {
     

          var isSuccess;

          var model = {
            ApplePayToken: event.payment.token,
            TrackId: apsApplePayEmbedded.trackId,
            PaymentMethod: "applepay",
          };



          try {
           //// to log the event.payment, comment this in production
            // const response1 = await fetch(
            //   "https://paldev.developerqa.info/api/aps/applepay-test",
            //   {
            //     method: "POST",
            //     headers: {
            //       "Content-Type": "application/json",
            //     },
            //     body: JSON.stringify(model),
            //   }
            // )

            // let response = await onSetApplePayInitiatePayment(model);

            onSetApplePayInitiatePayment(model);

            isSuccess = {
              status: 0,
            };
          } catch (error) {
            isSuccess = {
              status: 1,
            };
          }

          session.completePayment(isSuccess);

       

        };

        session.oncancel = event => {
          alert("Payment has been cancelled");
        };

        session.begin();
      }
    }

    applePayScript.onerror = function handleScriptError() {
      console.log("error loading script")
    }
    document.body.appendChild(applePayScript)
    return () => {
      document.body.removeChild(applePayScript)
    }
}
}}, [paymentRequest,apsApplePayEmbedded])

useEffect(() => {
    
}, [qrData])

function isSafariBrowser() {
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  return isSafari
}

  return (
    <React.Fragment>
      <Container>
        <div className="centered-div">

       <div className="d-flex flex-column center-items ">
                <div className="p-2">
                <apple-pay-button
                      className="apple-pay-button-v2"
                      // buttonstyle=""
                      type="plain"
                      locale="en"
                      
                    ></apple-pay-button>
                </div>
              </div>
                    
          {/* <Card className="apple-pay-container">
            <CardBody>
              <div className="d-flex flex-column center-items ">
                <div className="p-2">
                <apple-pay-button
                      className="apple-pay-button-v2"
                      buttonstyle="black"
                      type="plain"
                      locale="en"
                      
                    ></apple-pay-button>
                </div>
              </div>
            </CardBody>
           
          </Card> */}
        </div>
      </Container>
    </React.Fragment>
  )
}

ApplePayButton.propTypes = {
  paymentRequestDetail: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
}

const mapStateToProps = pr => (
  {
    retrievedPaymentRequest: pr.PaymentRequests.paymentRequestDetail,
    loading: pr.PaymentRequests.loading,
    error: pr.PaymentRequests.error,
    retrievedInitiatePaymentResponse: pr.Payment.initiatePayment
  }
)

const mapDispatchToProps = dispatch => ({
  onGetPaymentRequestDetail: id => dispatch(getPaymentRequestDetailPublic(id)),
  onSetApplePayInitiatePayment: data => dispatch(setApplePayInitiatePayment(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ApplePayButton)))
